<template>
    <div>
        <!--
            Only show league players leaderboard if the league's group teams have
            been filled.
        -->
        <span v-if="league.status === LeagueStatus.WAITING_FOR_TEAMS">
            {{ $t('leagues.messages.league_not_started') }}
        </span>

        <team-users-table
            v-else
            :team-users="teamUsers"
            :custom-columns="teamUsersColumns"
        />
    </div>
</template>

<script>
import {League} from '@/models/League';
import {TeamUsers} from '@/models/TeamUser';
import LeagueStatus from '@/library/enums/LeagueStatus';
import TeamUsersTable from '@/components/teamUsers/Table';

/**
 * The component to display TeamUsers leaderboard of the given league.
 */
export default {
    name: 'LeaguePlayers',
    components: {TeamUsersTable},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data: function() {
        return {
            /**
             * The TeamUsers leaderboard of the given league.
             */
            teamUsers: new TeamUsers([], {
                routePrefix: this.league.getFetchURL() + '/leaderboards',
            }),
            teamUsersColumns: {
                'user.handicap': {
                    hide: true,
                },
            },
            LeagueStatus,
        };
    },
};
</script>
